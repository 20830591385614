import React from "react"
import { graphql } from "gatsby"

export default ({ data }) => {
  return (
    <div className="container my-5 py-5">
        <h1>Privacy Policy</h1>
        <hr style={{ margin: `2rem 0` }} />
          {
          data.allWordpressPage.edges.map(({ node }) => {

            return (
                <div className="col-12" key={node.wordpress_id}>
                  <div dangerouslySetInnerHTML={{ __html: node.content }} />
                </div>
              )
          })
        }
    </div>
  )
}

export const pageQuery = graphql`
query {
  allWordpressPage(filter: {wordpress_id: {eq: 10361}}) {
    edges {
      node {
        title
        wordpress_id
        content
      }
    }
  }
}
`